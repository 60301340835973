import { type ReactNode, createContext, useContext, useState } from 'react'
import NotFound from './notFound'

interface ErrorContextType {
  isNotFound: boolean
  setNotFound: (isNotFound: boolean) => void
}

const ErrorContext = createContext<ErrorContextType | undefined>(undefined)

export const useError = () => {
  const context = useContext(ErrorContext)
  if (!context) {
    throw new Error('useError must be used within an ErrorProvider')
  }
  return context
}

export const ErrorProvider = ({ children }: { children: ReactNode }) => {
  const [isNotFound, setIsNotFound] = useState(false)

  return (
    <ErrorContext.Provider value={{ isNotFound, setNotFound: setIsNotFound }}>
      {isNotFound ? <NotFound /> : children}
    </ErrorContext.Provider>
  )
}
